<template>
  <div style="width: 1200px; margin: 0 auto">
    <el-image
      :src="$http.defaults.baseURL + state.config.DetailImg"
      style="width: 100%; height: 100%"
    />
    <div style="margin: 5px 0 10px 0; display: flex; align-items: center">
      您当前的位置：首页>
      <li v-for="(nav, index) in state.navs" :key="nav">
        <text v-if="index != 0">></text>
        {{ nav.Name }}
      </li>
    </div>
    <div style="display: flex" v-if="state.fMenu">
      <div style="width: 250px">
        <ul
          style="
            background: -webkit-gradient(
              linear,
              0 0,
              0 bottom,
              from(#4fa2d9),
              to(rgba(50, 112, 181, 1))
            );
            text-align: center;
            height: 40px;
            line-height: 40px;
            color: #fff;
          "
        >
          {{
            state.fMenu?.Name
          }}
        </ul>
        <div
          v-for="child in state.menus"
          :key="child"
          style="border-bottom: 1px dashed #000"
        >
          <ul
            class="menu"
            style="
              text-align: center;
              height: 40px;
              line-height: 40px;
              cursor: pointer;
              position: relative;
            "
            :class="state.selectId == child.Id ? 'menu-select' : ''"
            @click.stop="onMenuClick(child)"
          >
            {{
              child.Name
            }}
            <el-icon
              v-if="child.Children?.length > 0"
              color="#a2a2a2"
              style="position: absolute; top: 10px; right: 10px"
              ><CaretBottom
            /></el-icon>
          </ul>
          <div
            style="height: 0; overflow: hidden; transition: height 0.3s ease"
            :data-id="child.Id"
            ref="thirdMenu"
          >
            <ul
              v-for="child2 in child.Children"
              :key="child2"
              class="menu"
              @click.stop="onMenuClick(child2)"
              style="
                font-size: 15px;
                text-align: center;
                line-height: 28px;
                cursor: pointer;
              "
              :class="state.selectId == child2.Id ? 'menu-select' : ''"
            >
              {{
                child2.Name
              }}
            </ul>
          </div>
        </div>
      </div>
      <div
        style="
          background-color: #fff;
          flex: 1;
          margin-left: 20px;
          padding: 10px;
          overflow: hidden;
        "
      >
        <ul
          v-if="state.news.ShowTitle"
          style="font-weight: 600; text-align: center; font-size: 18px"
        >
          {{
            state.news?.Title
          }}
        </ul>
        <ul
          v-if="state.news?.ShowReadCount || state.news?.ShowPublishTime"
          style="
            color: #a2a2a2;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            font-size: 14px;
          "
        >
          <text v-if="state.news?.ShowPublishTime">
            发布时间：{{ state.news.PublishTime }}
          </text>
          <text v-if="state.news?.ShowReadCount" style="margin-left: 10px">
            阅读数：{{ state.news.InitReadCount + state.news.ReadCount }}
          </text>
        </ul>
        <div
          v-html="state.news?.Content"
          class="contentBox"
          style="margin-top: 20px"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import { home_getconfig, home_getnews } from "@/http/api";
import { useRoute, useRouter } from "vue-router";

export default {
  setup(props) {
    const thirdMenu = ref([]);
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      config: {},
      model: {},
      news: {},
    });
    onMounted(() => {
      home_getconfig().then((res) => {
        if (res.status == 200) state.config = res.data;
      });
      home_getnews(route.params.id).then((res) => {
        if (res.status == 200) {
          const { navs, fMenu, menus, model, news } = res.data;
          state.navs = navs;
          state.fMenu = fMenu;
          state.menus = menus;
          state.model = model;
          state.selectId = model.Id;
          state.news = news;

          setTimeout(() => {
            menus.forEach((e) => {
              if (e.Children.some((w) => w.Id == model.Id)) {
                onSecondMenuChange(e);
                return false;
              }
            });
          }, 200);
        }
      });
    });

    watch(
      () => route.params.id,
      (newid) => {
        home_getnews(newid).then((res) => {
          if (res.status == 200) {
            const { navs, fMenu, menus, model, news } = res.data;
            state.navs = navs;
            state.fMenu = fMenu;
            state.menus = menus;
            state.model = model;
            state.selectId = model.Id;
            state.news = news;

            setTimeout(() => {
              menus.forEach((e) => {
                if (e.Children.some((w) => w.Id == model.Id)) {
                  onSecondMenuChange(e);
                  return false;
                }
              });
            }, 200);
          }
        });
      }
    );

    const onSecondMenuChange = (child) => {
      let dom;
      thirdMenu.value.forEach((e) => {
        let id = e.getAttribute("data-id");

        if (id == child.Id) {
          dom = e;
          return false;
        }
      });

      setTimeout(() => {
        dom.style.height = child.Children?.length * 28 + "px";
      }, 200);
    };

    const onMenuClick = (menu) => {
      if (menu.Children.length > 0) {
        const currMenu = menu.Children[0];
        menu = currMenu;
      }

      if (menu.Template == 7) {
        const url = menu.LinkUrl;
        if (url.includes("http://") || url.includes("https://"))
          window.open(url, "_blank");
        else window.open("http://" + url, "_blank");
      } else {
        router.push(`/main/m/${menu.Id}`);
      }
    };
    return { state, props, onSecondMenuChange, thirdMenu, onMenuClick };
  },
};
</script>
<style>
div[data-w-e-type="video"] {
  max-width: 100%;
  text-align: center;

  video {
    max-width: 100%;
  }
}

.contentBox {
  img {
    max-width: 100%;
  }
}
</style>
